<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="商机编码" v-model="detail.code" :disabled="true"></inputItem>
          <inputItem title="商机名称" v-model="detail.name" :disabled="disabled"></inputItem>
          <inputItem title="客户名称" :initialContent="detail.customer_name" :disabled="disabled" type="others" ref="customer_name" :otherClicked="clickCutomer"></inputItem>
          <inputItem title="业务代表" v-model="detail.leader_name" :disabled="true"></inputItem>

          <inputItem title="商机类型" v-model="detail.category" :disabled="disabled" type="select" :options="categoryOption"></inputItem>
          <inputItem title="业务归属" v-model="detail.owner" :disabled="disabled" type="select" :options="ownerOption"></inputItem>
          <inputItem title="业务部门" v-model="detail.dept" :disabled="disabled" type="select" :options="deptOption"></inputItem>
          <inputItem title="分摊比例" :initialContent="deptRatio(detail.ratio)" :disabled="disabled" name="ratio" type="select" :options="ratioOption" :contentChanged="contentChanged"></inputItem>

          <inputItem title="商机备注" v-model="detail.remark" :disabled="disabled"></inputItem>
          <itemTotal :total="total"></itemTotal>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="商机清单" label="商机清单">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="index">
              <div class="item-header">
                <div class="item-index">清单{{ index + 1 }}</div>
                <img class="item-delete" src="@/static/images/icon_delete.png" alt @click="deleteItem(index)" v-if="!disabled" />
              </div>
              <div class="item-line">
                <div class="item-lable"> 商品名称 </div>
                <input class="input-left" v-model="item.goods_name" :disabled="disabled" />
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 销售数量 </div>
                  <input class="input-right" v-model="item.num" :disabled="disabled" @input="changeNum(item)" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 销售单价 </div>
                  <input class="input-right" v-model="item.price" :disabled="disabled" @input="changeNum(item)" />
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 销售金额 </div>
                  <input class="input-right" v-model="item.total" :disabled="disabled" @input="changeTotal(item)" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 询价成本 </div>
                  <div class="input-right"> {{ item.pre_cost }} </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 合计毛利 </div>
                  <div class="input-right"> {{ item.pre_profit }} </div>
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 总毛利率 </div>
                  <div class="input-right"> {{ item.pre_rate }} </div>
                </div>
              </div>
            </div>
            <div class="item-add" v-if="!disabled" alt @click="addItem"> 添加商品 </div>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="cutomerDrawer" v-if="showCutomers" :onBgClicked="onBgClicked" :onSearch="onSearchCutomer" :items="cutomers" @optionClicked="cutomerClicked"></optionDrawer>
  </div>
</template>

<script>
import { GetChance, SaveChance } from "@/api";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, submitAction, loadDataOptionAction, loadCustomerOptionAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      users: [],
      items: [],
      buttons: [],
      total: {
        total: null,
        cost: null,
        profit: null,
        rate: null,
      },
      disabled: false,
      categoryOption: [],
      ownerOption: [],
      deptOption: [],
      ratioOption: [],
      cutomers: [],
      showCutomers: false,
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    scrollToBottom: function () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.main.scrollTop = this.$refs.content.scrollHeight;
        }, 100);
      });
    },
    onSearchCutomer: function (value) {
      loadCustomerOptionAction(this, value, (data) => { this.cutomers = data; });
    },
    onBgClicked: function () {
      this.showCutomers = false;
    },
    deptRatio: function (value) {
      var res = this.ratioOption.filter(c => c.value == value);
      return (res && res.length > 0) ? res[0].text : "";
    },
    cutomerClicked: function (item) {
      this.detail.customer_id = item.id;
      this.detail.customer_name = item.name;
      this.$refs.customer_name.setContent(item.name);
      this.showCutomers = false;
    },
    clickCutomer: function () {
      this.showCutomers = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.cutomerDrawer.show();
        }, 100);
      });
    },
    addItem: function () {
      this.items.push({ goods_name: "", goods_unit: "", num: 1, price: 0, total: 0, remark: "", pre_price: 0, pre_cost: 0 });
    },
    deleteItem: function (index) {
      this.items.splice(index, 1);
      this.setTotal();
    },
    changeNum: function (item) {
      item.total = item.price * item.num;
      this.setTotal();
    },
    changeTotal: function (item) {
      item.price = item.total / item.num;
      this.setTotal();
    },
    setTotal: function () {
      var tot = this.total;
      tot.total = 0;
      tot.cost = 0;
      tot.profit = 0;
      tot.rate = 0;
      this.items.forEach(function (item) {
        tot.total += Number(item.total);
        tot.cost += Number(item.pre_cost);
      });
      tot.profit = tot.total - tot.cost;
      tot.rate = tot.total == 0 ? 0 : ((tot.profit * 100) / tot.total).toFixed(2);
    },
    contentChanged: function (index, value) {
      switch (index) {
        case "ratio":
          this.detail.ratio = value;
          break;
      }
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveChance();
          break;
      }
    },
    saveChance: function () {
      submitAction(this, SaveChance, { model: this.detail, items: this.items }, () => { this.$router.go(-1); });
    },
    dataRequest: function () {
      loadDetailAction(this, GetChance, { id: this.id, type: this.orderType }, (data) => {
        this.detail = data.list[0].model;
        this.items = data.list[0].items;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
        this.setTotal();
        this.$nextTick(() => { this.$refs.nav.refresh(); });
      });
    },
  },
  mounted() {
    loadDataOptionAction(this, "合同类型", (data) => { this.categoryOption = data; });
    loadDataOptionAction(this, "业务归属", (data) => { this.ownerOption = data; });
    loadDataOptionAction(this, "业务部门", (data) => { this.deptOption = data; });
    loadDataOptionAction(this, "分摊比例", (data) => { this.ratioOption = data; }, true);

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>